'use client'

import { useEffect } from 'react'

import { useRouter } from 'next/navigation'

import usePermissions from 'hooks/permissions/usePermissions'
import Routes from 'types/enums/routes'

const RootPage = () => {
  const router = useRouter()

  const { hasRoutePermissions } = usePermissions()

  useEffect(() => {
    if (hasRoutePermissions(Routes.BOOKINGS)) {
      router.push(Routes.BOOKINGS)
    } else router.push(Routes.SETTINGS)
  }, [router, hasRoutePermissions])

  return <></>
}

export default RootPage
